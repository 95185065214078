import React, { useState, FormEvent } from "react";
import {
    Box,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Link,
    Paper,
    TextField, useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form } from "react-router-dom";
import Typography from "@mui/material/Typography";
import NumberInputField from "../../Common/FormInputs/NumberInputField";
import ImageTemplateSelector from "./ImageTemplateSelector";
import MachineTypeSelector from "./MachineTypeSelector";
import { imageService } from "../../../services/Server/image.service";
import TextAreaField from "../../Common/FormInputs/TextAreaField";
import InputTags from "../../Common/FormInputs/InputTags";
import SimpleSnackbar from "../../Common/SnackBar/SnackBar";
import ServerValidationDialog from "./ServerValidationDialog";
import { useNavigate } from 'react-router-dom';
import {KeyboardReturn} from "@mui/icons-material";
import {URL_TEACHER_SERVERS} from "../../../router/urls";



const ServerCreator: React.FC = () => {
    const [os, setOs] = useState('');
    const [minDiskSize, setMinDiskSize] = useState(10);
    const [serverName, setServerName] = useState('');
    const [serverNameError, setServerNameError] = useState('');
    const [diskSize, setDiskSize] = useState(10);
    const [diskSizeError, setDiskSizeError] = useState('');
    const [services, setServices] = useState<string[]>([]);
    const [sshKey, setSshKey] = useState('');
    const [sshKeyError, setSshKeyError] = useState('');
    const [enableDisplay, setEnableDisplay] = useState(false);
    const [validationError, setValidationError] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const theme = useTheme();



    const handleTemplateSelect = (selectedOs: string, diskSize: number) => {
        setOs(selectedOs.toLowerCase());
        setMinDiskSize(diskSize);
        setDiskSize(diskSize);
    };


    const validateServerName = (name: string) => {
        const regex = /^[a-z]([-a-z0-9]*[a-z0-9])?$/;
        if (!regex.test(name)) {
            return "Name must start with a lowercase letter, followed by up to 55 lowercase letters, " +
                "numbers, or hyphens, and cannot end with a hyphen";
        }
        return '';
    };


    const validateDiskSize = (size: number) => {
        if (size < minDiskSize || size > 250) {
            return `Disk size must be between ${minDiskSize} and 250 GB`;
        }
        return '';
    };


    const validateSshKey = (key: string) => {
        if (os === 'linux' && !key) {
            return "SSH Public Key is required for Unix-based servers";
        }
        return '';
    };


    // Handlers
    const handleServerNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setServerName(value);
        setServerNameError(validateServerName(value));
    };


    const handleDiskSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(event.target.value);
        setDiskSize(value);
        setDiskSizeError(validateDiskSize(value));
    };


    const handleServicesChange = (updatedServices: string[]) => {
        setServices(updatedServices);
    }


    const handleSshKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSshKey(value);
        setSshKeyError(validateSshKey(value));
    };


    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnableDisplay(event.target.checked);
    };


    const navigate = useNavigate();


    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };


    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();


        const serverNameValidation = validateServerName(serverName);
        const diskSizeValidation = validateDiskSize(diskSize);
        const sshKeyValidation = validateSshKey(sshKey);

        if (serverNameValidation || diskSizeValidation || sshKeyValidation) {
            setServerNameError(serverNameValidation);
            setDiskSizeError(diskSizeValidation);
            setSshKeyError(sshKeyValidation);
        } else {
            const formData = new FormData(event.currentTarget);

            // Process remaining fields before creating formObject
            formData.set('enable_display', enableDisplay.toString());
            formData.set('disk_size', diskSize.toString());
            formData.set('server_name', serverName);
            formData.set('os', os);


            if (os === 'linux') {
                formData.set('ssh_key', sshKey);
            } else {
                formData.delete('ssh_key');
            }

            const formObject = Object.fromEntries(formData.entries());


            setIsLoading(true)
            setSnackbarMessage("Server creation is processing...");
            setSnackbarOpen(true);


            try {
                const resp = await imageService.create(formObject);
                console.log(resp);
                navigate('/teacher/servers/manage')
            } catch (error: any) {
                setValidationError(error);
                setIsLoading(false)
                setSnackbarOpen(false);
            }
        }
    };


    return (
        <Container sx={{mt: 10}}>
            <Box
                style={{
                    border: "none",
                    padding: "15px"
                }}
            >
                <Button
                    href={URL_TEACHER_SERVERS}
                    startIcon={<KeyboardReturn />}
                    variant={"outlined"}
                >
                    Back to Server Manager page
                </Button>
                <Typography
                    variant={"h4"}
                    component={"h1"}
                    textAlign={"left"}
                    fontFamily={"Roboto"}
                    gutterBottom={true}
                    marginTop={2}
                >
                    Create a server
                </Typography>
                <Typography
                    variant={"subtitle1"}
                    gutterBottom={true}
                >
                    Create a custom compute instance for lab development
                </Typography>
            </Box>


            <Paper
                elevation={1}
                square={false}
                style={{
                    margin: "0 10px",
                    padding: "15px",
                }}
            >
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <FormControl margin={"normal"} required={true}>
                            <TextField
                                id="server-name"
                                name="server_name"
                                label="Server Name*"
                                aria-label={"Name to call virtual machine"}
                                variant={"outlined"}
                                type={"text"}
                                helperText={
                                    serverNameError ||
                                    "Name must start with a lowercase letter followed by up to 55 " +
                                    "lowercase letters, numbers, or hyphens, and cannot end with a hyphen"
                                }
                                placeholder="sec-onion-123"
                                inputProps={{ maxLength: 55 }}
                                onChange={handleServerNameChange}
                                error={!!serverNameError}
                            />
                        </FormControl>
                    </FormGroup>


                    <Divider style={{ margin: "20px 0" }} />


                    <FormGroup
                        style={{
                            marginTop: 10,
                            padding: "10px"
                        }}
                    >
                        <Typography
                            variant={"h5"}
                            component={"h2"}
                            paddingBottom={2}
                        >
                            Machine Configuration
                        </Typography>
                        <FormControl margin={"normal"}>
                            <ImageTemplateSelector onTemplateSelect={handleTemplateSelect} />
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                margin={"0 10px"}
                            >
                                Existing Compute image to create machine instance with.
                                Includes custom images and official images provided by Google.
                            </Typography>
                        </FormControl>


                        <FormControl margin={"normal"} fullWidth>
                            <MachineTypeSelector />
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                margin={"0 10px"}
                                sx={{ mt: 1 }}
                            >
                                To learn more about machine types, refer to official documentation here:
                                <Link
                                    marginLeft={1}
                                    href="https://cloud.google.com/compute/docs/general-purpose-machines#e2_machine_types_table"
                                    target="_blank"
                                >
                                    Google Cloud Docs: E2 Machine Types
                                </Link>.
                            </Typography>
                        </FormControl>


                        <FormControl margin={"normal"}>
                            <input type="hidden" name="os" value={os.toLowerCase()} />
                            <NumberInputField
                                id={"diskSize"}
                                name={"disk_size"}
                                label={"Disk Size (GB)"}
                                min={minDiskSize}
                                max={250}
                                helperText={
                                    diskSizeError ||
                                    "Minimum instance size differs based on OS. " +
                                    "For example, Windows servers require a minimum disk size of 50GB."
                                }
                                onChange={handleDiskSizeChange}
                                value={diskSize || minDiskSize}
                                error={!!diskSizeError}
                            />
                        </FormControl>
                        <FormGroup>
                            <FormControlLabel
                                label="Enable display"
                                control={
                                    <Checkbox
                                        name={"enable_display"}
                                        checked={enableDisplay}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                            />
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                margin={"0 5px"}
                            >
                                If enabled, server will be added to the list of available connections
                                in proxy server.
                            </Typography>
                        </FormGroup>
                    </FormGroup>


                    <Divider style={{ margin: "20px 0" }} />


                    <FormGroup
                        style={{
                            marginTop: 10,
                            padding: "10px"
                        }}>
                        <Typography
                            variant={"h5"}
                            component={"h2"}
                            paddingBottom={2}
                        >
                            Management
                        </Typography>
                        <FormControl required={true}>
                            <TextAreaField
                                id={"description"}
                                name={"description"}
                                label={"Server description*"}
                                helperText={
                                    "General description up to 200 characters of machine purpose, " +
                                    "installed services, and/or configuration."
                                }
                                maxLength={200}
                                required={true}
                                maxRows={4}
                            />
                        </FormControl>
                        <FormControl>
                            <Typography paddingBottom={1}>Image Tags</Typography>
                            <InputTags
                                id="imageTags"
                                name="tags"
                                tagType={"tags"}
                                ariaLabel={"Image Tags input"}
                                helperText={
                                    "Comma delimited list of services and functionality provided on this server"
                                }
                                required={false}
                                initialTags={services} // Pass initial tags
                                onTagChanges={handleServicesChange}
                            />
                        </FormControl>


                        <Typography paddingTop={2}>Authorization</Typography>
                        <FormControl margin={"normal"}>
                            <TextField
                                required
                                name={"username"}
                                type={"text"}
                                label={"Username"}
                                placeholder={"Custom user to be added for initial access. Defaults to pantheon."}
                                helperText={
                                    "Must start with an alphabetic character, " +
                                    "cannot contains spaces or special characters, " +
                                    "and cannot exceed 20 characters in length"
                                }
                                inputProps={{ maxLength: 20 }}
                            />
                        </FormControl>
                        <FormControl margin={"normal"} style={{paddingTop: 5}}>
                            <TextAreaField
                                id="sshKey"
                                name="ssh_key"
                                label="SSH Public Key"
                                type="text"
                                required={os === 'linux'}
                                helperText={
                                    sshKeyError ||
                                    "Public key to authenticate custom user. Only required for Unix-based servers"
                                }
                                onInputChange={handleSshKeyChange}
                                error={!!sshKeyError}
                            />
                        </FormControl>
                    </FormGroup>


                    <LoadingButton
                        type="submit"
                        loading={isLoading}
                        loadingIndicator='Loading...'
                        variant={"contained"}
                        style={{
                            marginTop: 20,
                            alignSelf: "end"
                        }}
                    >
                        Create Server
                    </LoadingButton>

                    <ServerValidationDialog
                        title={"Server Create Error"}
                        open={openDialog}
                        onClose={() => setOpenDialog(false)}
                        textContent={validationError}
                    />
                </Form>
                {validationError && (
                    <ServerValidationDialog
                        title={"Server Create Error"}
                        open={openDialog}
                        onClose={() => setOpenDialog(false)}
                        textContent={validationError}
                    />
                )}
                <SimpleSnackbar
                    message={snackbarMessage}
                    open={snackbarOpen}
                    onClose={handleSnackbarClose}
                    disableAutoClose={false}
                    vertical="bottom"
                    horizontal="center"
                />


            </Paper>
        </Container>
    )
}


export default ServerCreator;