import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button
} from '@mui/material';

interface EditDialogProps {
    title: string;
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    formComponent: React.ComponentType<any>;
    formProps: any;
}

const EditFormDialog: React.FC<EditDialogProps> = (
    {
        title,
        open,
        onClose,
        onSave,
        formComponent: FormComponent,
        formProps
    }
) => {

    return (
        <Dialog open={open} onClose={onClose} disableEnforceFocus>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <FormComponent {...formProps} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">Cancel</Button>
                <Button onClick={onSave} color="success">Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditFormDialog;
