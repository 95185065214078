import React, { useCallback, useEffect, useState } from 'react';
import { Box, Skeleton, Button } from "@mui/material";
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import { useNavigate, useParams } from "react-router-dom";
import WorkoutTable from "./WorkoutTable";
import WorkoutAssessment from "./WorkoutAssessment";
import InfoCard from "../Common/InfoCard";
import TimerBox from "../Common/Timer";
import { WorkoutFull } from "../../services/Workout/workout.model";
import { workoutService } from "../../services/Workout/workout.service";
import HttpError from "../Common/Errors/HttpError";
import {URL_ERROR} from "../../router/urls";

const StudentWorkout: React.FC = () => {
    const { build_id } = useParams<{ build_id: string }>();
    const [isLoading, setIsLoading] = useState(true);
    const [workoutFull, setWorkoutFull] = useState<WorkoutFull | null>(null);
    const [showAssessment, setShowAssessment] = useState(false);
    const [error, setError] = useState<HttpError | null>(null);
    const navigate = useNavigate();

    const handleError = useCallback(
        (error: HttpError) => {
            if ([404].includes(error.status)) {
                navigate(URL_ERROR, {
                    state: {
                        status: error.status,
                        message: error.message || "An error occurred",
                    },
                });
            } else {
                navigate(URL_ERROR, {
                    state: {
                        status: 500,
                        message: "Something went wrong!",
                    },
                });
            }
        },
        [navigate]
    );

    const fetchWorkout = useCallback(async () => {
        try {
            const fetchedWorkout = await workoutService.get_full(String(build_id));
            setWorkoutFull(fetchedWorkout);
        } catch (error) {
            handleError(error as HttpError);
            setError(error as HttpError);
        } finally {
            setIsLoading(false);
        }
    }, [build_id, handleError]);

    useEffect(() => {
        fetchWorkout();
    }, [fetchWorkout]);

    useEffect(() => {
        fetchWorkout();

        let intervalTime = 3500;
        const serverCount = workoutFull?.servers?.length || 0;

        if (serverCount === 0) {
            intervalTime = 60000;
        }

        const intervalId = setInterval(fetchWorkout, intervalTime);
        return () => clearInterval(intervalId);
    }, [fetchWorkout, workoutFull?.servers?.length]);


    return (
        <>
            <Box
                id="homePage"
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'start',
                    mt: 10,
                    pl: 2,
                    pr: 2
                }}
            >
                <Box
                    sx={{
                        width: '25%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mr: 4
                    }}
                >
                    {isLoading ? (
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={150}
                            animation="wave"
                            sx={{
                                bgcolor: 'rgba(0, 0, 0, 0.1)'
                            }}
                        />
                    ) : (
                        <Box sx={{ width: '100%' }}>
                            <TimerBox
                                type="workout"
                                build_id={String(build_id)}
                                workoutData={workoutFull?.workout}
                            />
                            <Button
                                variant={"outlined"}
                                onClick={() => setShowAssessment(!showAssessment)}
                                sx={{ mt: 2 }}
                                endIcon={<KeyboardTabIcon />}
                            >
                                {showAssessment ? 'Back to Workout' : 'Go to Assessment'}
                            </Button>
                        </Box>
                    )}
                </Box>
                <Box
                    sx={{
                        width: '75%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    {isLoading || !workoutFull ? (
                        <>
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={200}
                                animation="wave"
                                sx={{ bgcolor: 'rgba(0, 0, 0, 0.1)' }}
                            />
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={500}
                                animation="wave"
                                sx={{ mt: 2, bgcolor: 'rgba(0, 0, 0, 0.1)' }}
                            />
                        </>
                    ) : showAssessment ? (
                        <WorkoutAssessment workout={workoutFull.workout} />
                    ) : (
                        <>
                            <InfoCard type='workout' data={{ ...workoutFull.workout }} />
                            <WorkoutTable workoutFull={workoutFull} />
                        </>
                    )}
                </Box>
            </Box>
        </>
    );
}

export default StudentWorkout;
