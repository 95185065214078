import React, { useState, useEffect } from 'react';
import {
    Box,
    Checkbox,
    Typography,
    Chip,
    IconButton,
    Modal,
    Paper,
    Button,
    TextField,
    InputAdornment,
    Tooltip,
    useTheme
} from '@mui/material';
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    Clear as ClearIcon
} from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { userService } from '../../services/User/user.service';
import { AgogeUser } from '../../services/User/user.model';
import { DataGrid } from "@mui/x-data-grid";
import SimpleSnackbar from "../Common/SnackBar/SnackBar";

const permissionColors: { [key: string]: { backgroundColor: string, color: string, borderRadius: string } } = {
    'admin': { backgroundColor: 'red', color: 'white', borderRadius: '4px' },
    'instructor': { backgroundColor: 'green', color: 'white', borderRadius: '4px' },
    'student': { backgroundColor: 'blue', color: 'white', borderRadius: '4px' },
};

const AgogeUserTable: React.FC = () => {
    const [userList, setUserList] = useState<AgogeUser[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<AgogeUser[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [editRowId, setEditRowId] = useState<string | null>(null);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<AgogeUser | null>(null);
    const [editedPermissions, setEditedPermissions] = useState<{ [key: string]: boolean }>({});
    const [searchQuery, setSearchQuery] = useState<string>('');
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const [createUserModalOpen, setCreateUserModalOpen] = useState<boolean>(false);
    const [newUserEmail, setNewUserEmail] = useState<string>('');
    const [newUserPermissions, setNewUserPermissions] = useState<{ [key: string]: boolean }>({
        admin: false,
        instructor: false,
        student: false,
    });

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const fetchedUsers = await userService.list();
                setUserList(fetchedUsers);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        fetchUsers();
    }, []);

    useEffect(() => {
        setFilteredUsers(userList.filter(user => user.email.toLowerCase().includes(searchQuery.toLowerCase())));
    }, [userList, searchQuery]);

    const handleEditClick = (rowId: string) => {
        setEditRowId(editRowId === rowId ? null : rowId);
        const user = userList.find(user => user.email === rowId);
        setEditedPermissions(user?.permissions || {});
        setSelectedUser(user || null);
    };

    const handleCheckboxChange = (role: string) => {
        setEditedPermissions(prevPermissions => ({
            ...prevPermissions,
            [role]: !prevPermissions[role]
        }));
    };

    const handleSaveClick = async () => {
        if (selectedUser) {
            selectedUser.permissions = editedPermissions;
            try {
                await userService.update_permissions(selectedUser.uid, selectedUser);
                setSnackbarMessage("Successfully updated settings!");
                setSnackbarOpen(true);
            } catch (error) {
                setSnackbarMessage("Failed to update settings.");
                setSnackbarOpen(true);
            }
        }
        setSelectedUser(null);
        if (editRowId) {
            const updatedUserList = userList.map(user => {
                if (user.email === editRowId) {
                    return { ...user, permissions: editedPermissions };
                }
                return user;
            });
            setUserList(updatedUserList);
            setFilteredUsers(updatedUserList);
            setEditRowId(null);
        }
    };

    const handleDeleteClick = (user: AgogeUser) => {
        setSelectedUser(user);
        setOpenModal(true);
    };

    const handleModalClose = () => {
        setOpenModal(false);
        setSelectedUser(null);
    };

    const handleConfirmDelete = async () => {
        if (selectedUser) {
            try {
                await userService.delete_user(selectedUser.uid);
                setSnackbarMessage("Successfully deleted user!");
                setUserList(prevUserList => prevUserList.filter(user => user.uid !== selectedUser.uid));
                setFilteredUsers(prevFilteredUsers => prevFilteredUsers.filter(user => user.uid !== selectedUser.uid));
                setSnackbarOpen(true);
            } catch (error) {
                setSnackbarMessage("Failed to delete user!");
                setSnackbarOpen(true);
            }
            setOpenModal(false);
            setSelectedUser(null);
        }
    };

    const isUserPending = (user: AgogeUser) => {
        return !user.permissions?.admin && !user.permissions?.instructor && !user.permissions?.student;
    };

    const renderPermissionsCell = (params: any, role: string) => {
        const isEditing = editRowId === params.row.email;
        if (isEditing) {
            return (
                <Checkbox
                    checked={editedPermissions[role] || false}
                    onChange={() => handleCheckboxChange(role)}
                />
            );
        } else if (params.row.permissions[role]) {
            return (
                <Chip
                    label={role.charAt(0).toUpperCase() + role.slice(1)}
                    style={permissionColors[role]}
                />
            );
        } else {
            return null;
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        setSearchQuery(query);
    };

    const handleClearSearch = () => {
        setSearchQuery('');
    };

    const handleCreateUserClick = () => {
        setCreateUserModalOpen(true);
    };

    const handleCreateUserModalClose = () => {
        setCreateUserModalOpen(false);
        setNewUserEmail('');
        setNewUserPermissions({
            admin: false,
            instructor: false,
            student: false,
        });
    };

    const handleNewUserCheckboxChange = (role: string) => {
        setNewUserPermissions(prevPermissions => ({
            ...prevPermissions,
            [role]: !prevPermissions[role]
        }));
    };

    const handleCreateUser = async () => {
        const newUser = {
            email: newUserEmail,
            permissions: newUserPermissions,
        };
        try {
            const response = await userService.create(newUser);
            setSnackbarMessage("Successfully created user!");
            setUserList(prevUserList => [...prevUserList, response]);
            setFilteredUsers(prevFilteredUsers => [...prevFilteredUsers, response]);
            setSnackbarOpen(true);
        } catch (error) {
            setSnackbarMessage("Failed to create user!");
            setSnackbarOpen(true);
        }
        handleCreateUserModalClose();
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    }

    const columns = [
        {
            field: "rowIndex",
            headerName: "",
            filterable: false,
            sortable: false,
            width: 60,
            renderCell: (params: any) => params.api.getAllRowIds().indexOf(params.id) + 1
        },
        {
            field: 'user', headerName: 'User', width: 300,
            sortable: false,
            flex: 1,
            renderCell: (params: any) => (
                <Box
                    sx={{ fontWeight: 'bold', fontSize: 16 }}
                >
                    {params.row.email}
                </Box>
            )
        },
        {
            field: 'pending',
            headerName: 'Status',
            width: 100,
            sortable: false,
            renderCell: (params: any) => {
                if (isUserPending(params.row)) {
                    return (
                        <Chip
                            label="Pending"
                            style={{ backgroundColor: 'rgb(245, 124, 0)', color: 'black', borderRadius: '4px' }}
                        />
                    );
                } else {
                    return (
                        <Chip
                            label="Active"
                            style={{ backgroundColor: "gray", color: 'black', borderRadius: '4px' }}
                        />
                    );
                }
            }
        },
        {
            field: 'admin', headerName: 'Admin', width: 100,
            sortable: false,
            renderCell: (params: any) => renderPermissionsCell(params, 'admin')
        },
        {
            field: 'instructor', headerName: 'Instructor', width: 105,
            sortable: false,
            renderCell: (params: any) => renderPermissionsCell(params, 'instructor')
        },
        {
            field: 'student', headerName: 'Student', width: 100,
            sortable: false,
            renderCell: (params: any) => renderPermissionsCell(params, 'student')
        },
        {
            field: 'actions', headerName: 'Actions', flex: 1,
            sortable: false,
            renderCell: (params: any) => (
                <Box>
                    <Tooltip title="Edit">
                        <IconButton onClick={() => handleEditClick(params.row.email)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton onClick={() => handleDeleteClick(params.row)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                    {editRowId === params.row.email && (
                        <Button
                            onClick={handleSaveClick}
                            variant="contained"
                            color="primary"
                            startIcon={<SaveIcon />}
                        >
                            Save
                        </Button>
                    )}
                </Box>
            )
        }
    ];

    return (
        <Box sx={{ height: "auto", width: "100%" }}>
            <Box sx={{ width: 'calc(60vw)', m: 5 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateUserClick}
                    sx={{ mb: 2 }}
                    startIcon={<PersonAddIcon />}
                >
                    Create User
                </Button>
                <TextField
                    label="Search by Email"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    InputProps={{
                        endAdornment: searchQuery && (
                            <InputAdornment position="end">
                                <IconButton onClick={handleClearSearch}>
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <Paper style={{ height: '600px', width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ flexGrow: 1, minHeight: 0 }}>
                        <DataGrid
                            density={"comfortable"}
                            rows={filteredUsers}
                            columns={columns}
                            getRowId={(row) => row.email}
                            loading={isLoading}
                            sx={{ height: "100%", minHeight: "0" }}
                        />
                    </Box>
                </Paper>
                <Modal
                    open={openModal}
                    onClose={handleModalClose}
                    aria-labelledby="confirm-delete-title"
                    aria-describedby="confirm-delete-description"
                    closeAfterTransition
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100vh',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        }}
                    >
                        <Paper
                            elevation={3}
                            sx={{
                                padding: 3,
                                borderRadius: 1,
                                boxShadow: 24,
                                outline: 'none',
                                width: 400,
                                maxWidth: '80%',
                            }}
                        >
                            <Typography id="confirm-delete-title" variant="h6" component="h2">
                                Confirm Deletion
                            </Typography>
                            <Typography id="confirm-delete-description" sx={{ mt: 2 }}>
                                Are you sure you want to delete this user?
                            </Typography>
                            <Button onClick={handleConfirmDelete} variant="contained" color="secondary" sx={{ mt: 2, mr: 2 }}>
                                Confirm
                            </Button>
                            <Button onClick={handleModalClose} variant="contained" sx={{ mt: 2 }}>
                                Cancel
                            </Button>
                        </Paper>
                    </Box>
                </Modal>
                <Modal
                    open={createUserModalOpen}
                    onClose={handleCreateUserModalClose}
                    aria-labelledby="create-user-title"
                    aria-describedby="create-user-description"
                    closeAfterTransition
                    slotProps={{
                        backdrop: {
                            timeout: 500
                        }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100vh',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        }}
                    >
                        <Paper
                            elevation={3}
                            sx={{
                                padding: 3,
                                borderRadius: 1,
                                backgroundColor: 'background.paper',
                                boxShadow: 24,
                                outline: 'none',
                                width: 400,
                                maxWidth: '80%',
                            }}
                        >
                            <Typography id="create-user-title" variant="h6" component="h2">
                                Create New User
                            </Typography>
                            <TextField
                                label="Email"
                                value={newUserEmail}
                                onChange={(e) => setNewUserEmail(e.target.value)}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                            />
                            <Box>
                                {Object.keys(newUserPermissions).map(role => (
                                    <Box key={role} display="flex" alignItems="center">
                                        <Checkbox
                                            checked={newUserPermissions[role]}
                                            onChange={() => handleNewUserCheckboxChange(role)}
                                        />
                                        <Typography>{role.charAt(0).toUpperCase() + role.slice(1)}</Typography>
                                    </Box>
                                ))}
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                <Button onClick={handleCreateUser} variant="contained" color="primary">
                                    Create User
                                </Button>
                                <Button onClick={handleCreateUserModalClose} variant="outlined" color="secondary">
                                    Cancel
                                </Button>
                            </Box>
                        </Paper>
                    </Box>
                </Modal>

                <SimpleSnackbar open={snackbarOpen} message={snackbarMessage} onClose={handleSnackbarClose} />
            </Box>
        </Box>
    );
};

export default AgogeUserTable;